<template>
    <div class="personAdmin">
        <div class="facilityBox">
            <el-form inline class="searchForm" :model="searchForm" ref="searchForm">
                <el-form-item label="司机姓名：" prop="driverName">
                    <el-input v-model="searchForm.driverName" placeholder="请输入司机姓名">
                    </el-input>
                </el-form-item>
                <el-form-item label="身份证号：" prop="driverIDCard">
                    <el-input v-model="searchForm.driverIDCard" placeholder="请输入司机身份证号码">
                    </el-input>
                </el-form-item>
                <el-form-item label="手机号码：" prop="telPhone">
                    <el-input v-model="searchForm.telPhone" placeholder="请输入司机手机号码">
                    </el-input>
                </el-form-item>
                <el-form-item label="所属企业名称：" prop="ascriptionUserName">
                    <el-input v-model="searchForm.ascriptionUserName" placeholder="请输入司机所属企业名称">
                    </el-input>
                </el-form-item>
                <el-form-item label-width="10px">
                    <el-button type="primary" size="medium" @click="search" icon="el-icon-search">搜索
                    </el-button>
                    <el-button type="primary" size="medium" @click="resetForm" icon="el-icon-delete">清空</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" ref="table" :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
                v-loading="loading">
                <el-table-column align="center" type="index" label="序号" width="50" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" label="司机姓名" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverIDCard" label="司机身份证号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TelPhone" label="司机手机号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="StatusName" label="当前状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.Status == 1">{{ scope.row.StatusName }}</el-tag>
                        <el-tag type="danger" v-if="scope.row.Status == 2">{{ scope.row.StatusName }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.Status == 4">{{ scope.row.StatusName }}</el-tag>
                        <el-tag v-if="scope.row.Status != 1 && scope.row.Status != 2 && scope.row.Status != 4">{{ scope.row.StatusName }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="AscriptionUserName" label="归属企业" show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div style="text-align: left; margin-top: 10px">
                <el-pagination background @current-change="handleCurrentChange" :current-page.sync="pagination.page"
                    :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
                    :total="pagination.total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {
    GetDriverAll
} from "@/api/transport/driverManage/index";
export default {
    data() {
        return {
            loading: false,
            pagination: {
                //分页控件相关参数
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
                total: 0, //总条数
            },
            searchForm: {
                telPhone: "",
                driverName: "",
                driverIDCard: "",
                ascriptionUserName: ""
            },
            tableData: [], //表格数据
        };
    },
    methods: {
        //分页控件页码change事件回调
        handleCurrentChange(e) {
            this.pagination.page = e
            this.getDriverAll()
        },
        //搜索
        search() {
            this.pagination.page = 1;
            this.getDriverAll()
        },
        //清空
        resetForm() {
            this.searchForm = {
                telPhone: "",
                driverName: "",
                driverIDCard: "",
                ascriptionUserName: ""
            }
            this.pagination.page = 1;
            this.getDriverAll()
        },
        // 获取列表
        getDriverAll() {
            let params = {
                ...this.searchForm,
                pageSize: this.pagination.pagesize,
                pageIndex: this.pagination.page
            };
            this.loading = true
            GetDriverAll(params).then((res) => {
                this.tableData = res.data.DataList;
                this.pagination.total = Number(res.data.TotalCount);
                this.loading = false
            }).finally(() => {
                this.loading = false
            })
        },
    },
    created() {
        this.getDriverAll()
    },
};
</script>
<style lang="scss" scoped>

</style>